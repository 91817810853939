export const OWNERS = [
  'alain',
  'delphine',
  'fabien',
  'jordan',
  'kais',
  'laurent',
  'myriam',
  'sophie',
  'vinicius',
]
