import Vue from 'vue'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'

import { createRoutes } from './routes'

Vue.use(Router)

export function createRouter(services, store) {
  const router = new Router({
    mode: 'history',
    routes: createRoutes(services),
  })

  router.beforeEach((to, from, next) => {
    if (typeof window !== 'undefined') {
      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !store.state.auth.isAuthenticated
      ) {
        return next({ name: 'login' })
      }
    }
    next()
  })

  // Synchronize the router with the vuex store
  sync(store, router, { moduleName: 'route' })

  return router
}
