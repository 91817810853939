import { SPOT_TRACKER_TYPE, QUECLINK_TRACKER_TYPE } from '@constants/tracker/type'

export class LiveVehicleService {
  constructor(api) {
    this.api = api
  }

  async create({
    liveCategoryId,
    spotTrackerId,
    queclinkTrackerId,
    number,
    name,
    country,
    accessType,
    gender,
    comment,
  }) {
    return await this.api.post('/live_vehicles', {
      liveCategoryId,
      spotTrackerId,
      queclinkTrackerId,
      number,
      name,
      country,
      accessType,
      gender,
      comment,
    })
  }

  async saveAll(liveId, liveVehicles) {
    return await this.api.post(`/lives/${liveId}/vehicles/import`, {
      liveId,
      liveVehicles,
    })
  }

  async getAll(liveId) {
    return await this.api.get(`/lives/${liveId}/vehicles`)
  }

  async getAllBySpotTrackerId(trackerId) {
    return await this.api.get(`/spot_trackers/${trackerId}/live_vehicles`, {
      trackerType: SPOT_TRACKER_TYPE,
    })
  }

  async getById(id) {
    return await this.api.get(`/live_vehicles/${id}`)
  }

  async getAllByQueclinkTrackerId(trackerId) {
    return await this.api.get(`/queclink_trackers/${trackerId}/live_vehicles`, {
      trackerType: QUECLINK_TRACKER_TYPE,
    })
  }

  async exportGpx(id, name) {
    return await this.api.get(`/live_vehicles/${id}/locations`, { name })
  }

  async update(
    id,
    {
      liveCategoryId,
      spotTrackerId,
      queclinkTrackerId,
      number,
      name,
      country,
      accessType,
      gender,
      comment,
    },
  ) {
    return await this.api.put(`/live_vehicles/${id}`, {
      liveCategoryId,
      spotTrackerId,
      queclinkTrackerId,
      number,
      name,
      country,
      accessType,
      gender,
      comment,
    })
  }

  async delete(id) {
    return await this.api.delete(`/live_vehicles/${id}`)
  }

  async deleteAll(liveId) {
    return await this.api.delete(`/lives/${liveId}/vehicles`)
  }
}
