export class LiveSetupFormService {
  constructor(api) {
    this.api = api
  }

  async getAllForms() {
    return await this.api.get('/live_setup_forms')
  }

  async getAllFormSubmissions(id) {
    return await this.api.get(`/live_setup_forms/${id}/submissions`)
  }

  async getFormSubmission(id) {
    return await this.api.get(`/live_setup_submissions/${id}`)
  }
}
