<template>
  <o-table :items="filteredLiveDetails" :loading="loading" :draggable="false">
    <b-table-column v-slot="{ row }" field="live.name" label="Nom">
      <router-link :to="{ name: 'live.edit.informations', params: { id: row.live.id } }">
        {{ row.live.name }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="trackerCount" label="Balises">
      {{ row.trackerCount }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="unavailableTrackerCount" label="Balises non rendues">
      {{ row.unavailableTrackerCount }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="live.startedAt" label="Début">
      {{ formatDate(row.live.startedAt) }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="live.endedAt" label="Fin">
      {{ formatDate(row.live.endedAt) }}
    </b-table-column>
  </o-table>
</template>

<script>
import { format } from 'date-fns'
import OTable from '@components/Table.vue'

export default {
  name: 'LiveDetailTable',

  components: {
    OTable,
  },

  props: {
    liveDetails: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          ['live', 'trackerCount', 'unavailableTrackerCount'].every((key) => key in obj),
        ),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    filteredLiveDetails() {
      return this.liveDetails.filter((liveDetail) => liveDetail.unavailableTrackerCount > 0)
    },
  },

  methods: {
    formatDate(date) {
      return !date ? 'Aucune date' : format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
