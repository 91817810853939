export const INTERNAL_ERROR_TYPE = 'internal-error'
export const TOO_MANY_REQUESTS_ERROR_TYPE = 'too-many-requests'

export class ApiError extends Error {
  constructor(type, message, details = null, debug = null) {
    super(message)
    this.type = type
    this.details = details
    this.debug = debug
  }
}
