<template>
  <main class="has-navbar-fixed-top" :class="classList">
    <app-navbar />
    <app-sidebar />
    <router-view />
    <app-notifications />
  </main>
</template>

<script>
import AppNavbar from './AppNavbar.vue'
import AppSidebar from './AppSidebar.vue'
import AppNotifications from './AppNotifications.vue'
import { mapState } from 'vuex'

export default {
  name: 'AppLayout',

  components: {
    [AppNavbar.name]: AppNavbar,
    [AppSidebar.name]: AppSidebar,
    [AppNotifications.name]: AppNotifications,
  },

  computed: {
    ...mapState('ui', ['sidebar']),

    classList() {
      return {
        'has-sidebar-expanded': this.sidebar.isExpanded,
      }
    },
  },
}
</script>
