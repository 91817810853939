<template>
  <div class="columns is-multiline mt-5">
    <h2 class="column is-full has-text-weight-bold">
      Balises
      <b-tag :type="tags[availability].type" size="is-medium" class="has-text-weight-normal">
        {{ tags[availability].label }}
      </b-tag>
    </h2>
    <div class="column is-narrow is-flex pt-0 is-flex-wrap-wrap">
      <div
        v-for="(count, owner) in filteredTrackerStatsOwners"
        :key="owner"
        class="is-flex is-flex-direction-column is-align-items-center mr-5 is-flex-wrap-wrap"
      >
        <span class="is-capitalized">{{ owner }}</span>
        <span v-if="loading" class="has-text-primary has-text-weight-bold is-size-5"> — </span>
        <router-link
          v-else
          :to="{
            name: trackerListRouteName,
            query: { owner, availability },
          }"
          class="has-text-primary has-text-weight-bold is-size-5"
        >
          {{ count }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AVAILABILITY_AVAILABLE,
  AVAILABILITY_BROKEN,
  AVAILABILITY_LOST,
  AVAILABILITY_REFORMED,
  AVAILABILITY_UNAVAILABLE,
} from '@constants/tracker/availability'
import { TAGS } from '@constants/tracker/tag'

export default {
  name: 'TrackerStatsOwners',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    availability: {
      type: String,
      required: true,
      validator: (value) =>
        [
          AVAILABILITY_LOST,
          AVAILABILITY_BROKEN,
          AVAILABILITY_REFORMED,
          AVAILABILITY_AVAILABLE,
          AVAILABILITY_UNAVAILABLE,
        ].indexOf(value) !== -1,
    },
    trackerStatsOwners: {
      type: Object,
      required: true,
    },
    trackerListRouteName: {
      type: String,
      required: true,
    },
  },

  created() {
    this.tags = TAGS
  },

  computed: {
    filteredTrackerStatsOwners() {
      return Object.keys(this.trackerStatsOwners).reduce(
        (trackerStatsOwners, owner) =>
          this.trackerStatsOwners[owner] > 0
            ? {
                ...trackerStatsOwners,
                [owner]: this.trackerStatsOwners[owner],
              }
            : trackerStatsOwners,
        {},
      )
    },
  },
}
</script>
