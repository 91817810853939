import { parseISO } from 'date-fns'

export class QueclinkTrackerService {
  constructor(api) {
    this.api = api
  }

  async getAll({ keyword, availability, owner }) {
    return (
      await this.api.get('/queclink_trackers', {
        keyword,
        availability,
        owner,
      })
    ).map((queclinkTracker) => this.parse(queclinkTracker))
  }

  async getAllByLiveId(liveId) {
    return (await this.api.get(`/lives/${liveId}/queclink_trackers`)).map(this.parse)
  }

  async getById(id) {
    return this.parse(await this.api.get(`/queclink_trackers/${id}`))
  }

  async getLatestLocation(id) {
    return await this.api.get(`/queclink_trackers/${id}/latest_location`)
  }

  async create({ number, imei, purchasedAt, comment, owner }) {
    return this.parse(
      await this.api.post('/queclink_trackers', {
        number,
        imei,
        purchasedAt,
        comment,
        owner,
      }),
    )
  }

  async update(id, { availability, comment, owner }) {
    return this.parse(
      await this.api.put(`/queclink_trackers/${id}`, {
        availability,
        comment,
        owner,
      }),
    )
  }

  async updateAll({ queclinkTrackers }) {
    return (
      await this.api.put('/queclink_trackers/import', {
        queclinkTrackers,
      })
    ).map((queclinkTracker) => this.parse(queclinkTracker))
  }

  parse(queclinkTracker) {
    queclinkTracker.purchasedAt = parseISO(queclinkTracker.purchasedAt)

    return queclinkTracker
  }
}
