<template>
  <b-collapse animation="slide" aria-id="contentIdForA11y1">
    <template #trigger="props">
      <div
        aria-controls="contentIdForA11y1"
        :aria-expanded="props.open"
        role="button"
        class="card-header"
      >
        <h2 class="card-header-title">{{ title }}</h2>
        <div class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
        </div>
      </div>
    </template>
    <div class="has-background-white-ter p-4 content">
      <tracker-stats-availabilities
        :loading="loading"
        :trackerStats="trackerStats"
        :tracker-list-route-name="trackerListRouteName"
      />

      <tracker-stats-owners
        :loading="loading"
        :availability="availability"
        :trackerStatsOwners="trackerStatsOwners"
        :tracker-list-route-name="trackerListRouteName"
      />

      <live-detail-table :liveDetails="liveDetails" :loading="loading" />
    </div>
  </b-collapse>
</template>

<script>
import { OWNERS } from '@constants/tracker/owner'
import { AVAILABILITY_AVAILABLE } from '@constants/tracker/availability'
import LiveDetailTable from '@views/LiveDetail/LiveDetailTable'
import TrackerStatsOwners from '@views/TrackerStat/TrackerStatsOwners'
import TrackerStatsAvailabilities from '@views/TrackerStat/TrackerStatsAvailabilities'

export default {
  name: 'TrackerStats',

  components: {
    LiveDetailTable,
    TrackerStatsOwners,
    TrackerStatsAvailabilities,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    trackerListRouteName: {
      type: String,
      required: true,
    },
    trackerStats: {
      type: Object,
      required: true,
      validator: (v) => ['availabilities', 'owners'].every((key) => key in v),
    },
    liveDetails: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          ['live', 'trackerCount', 'unavailableTrackerCount'].every((key) => key in obj),
        ),
    },
  },

  created() {
    this.owners = OWNERS
    this.availability = AVAILABILITY_AVAILABLE
  },

  computed: {
    trackerStatsOwners() {
      return this.trackerStats.owners
        ? this.owners.reduce(
            (owners, owner) => ({
              ...owners,
              [owner]: this.trackerStats.owners[owner]
                ? this.trackerStats.owners[owner][this.availability]
                : '—',
            }),
            {},
          )
        : this.owners.reduce((owners, owner) => ({ ...owners, [owner]: '—' }), {})
    },
  },
}
</script>
