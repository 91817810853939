<template>
  <div class="notices is-bottom">
    <b-notification
      v-for="message in toast.messages"
      :key="message.id"
      :type="message.type"
      class="is-bottom-right"
    >
      {{ message.text }}
    </b-notification>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppNotifications',

  computed: {
    ...mapState('ui', ['toast']),
  },
}
</script>
