export default class InMemoryStorage {
  constructor() {
    this.data = {}
  }

  setItem(key, value) {
    this.data[key] = value
  }

  getItem(key) {
    return this.data[key] || null
  }

  removeItem(key) {
    delete this.data[key]
  }
}
