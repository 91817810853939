export class AdministratorService {
  constructor(api) {
    this.api = api
  }

  async getAll() {
    return await this.api.get('/administrators')
  }

  async getById(id) {
    return await this.api.get(`/administrators/${id}`)
  }

  async update(id, { firstname, lastname, email }) {
    return await this.api.put(`/administrators/${id}`, {
      firstname,
      lastname,
      email,
    })
  }

  async create(id, { firstname, lastname, email, password }) {
    return await this.api.post('/administrators', {
      firstname,
      lastname,
      email,
      password,
    })
  }
}
