export class LiveTutorialService {
  constructor(api) {
    this.api = api
  }

  async create({ title, videoUrl, description, summary, accessType }) {
    return this.parse(
      await this.api.post('/live_tutorials', {
        title,
        videoUrl,
        description,
        summary,
        accessType,
      }),
    )
  }

  async getAll() {
    return (await this.api.get(`/live_tutorials`)).map((liveTutorial) => this.parse(liveTutorial))
  }

  async update({ id, accessType, title, videoUrl, description, summary, position }) {
    return this.parse(
      await this.api.put(`/live_tutorials/${id}`, {
        id,
        accessType,
        title,
        videoUrl,
        description,
        summary,
        position,
      }),
    )
  }

  async delete(id) {
    return this.parse(await this.api.delete(`/live_tutorials/${id}`))
  }

  parse(liveTutorial) {
    liveTutorial.updatedAt = new Date(liveTutorial.updatedAt)

    return liveTutorial
  }
}
