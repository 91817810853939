import {
  AVAILABILITIES,
  AVAILABILITY_LOST,
  AVAILABILITY_BROKEN,
  AVAILABILITY_REFORMED,
  AVAILABILITY_AVAILABLE,
  AVAILABILITY_UNAVAILABLE,
} from '@constants/tracker/availability.js'

export const TAGS = {
  [AVAILABILITY_AVAILABLE]: {
    label: AVAILABILITIES[AVAILABILITY_AVAILABLE],
    type: 'is-light is-success',
  },
  [AVAILABILITY_UNAVAILABLE]: {
    label: AVAILABILITIES[AVAILABILITY_UNAVAILABLE],
    type: 'is-light is-warning',
  },
  [AVAILABILITY_LOST]: {
    label: AVAILABILITIES[AVAILABILITY_LOST],
    type: 'is-light is-danger',
  },
  [AVAILABILITY_BROKEN]: {
    label: AVAILABILITIES[AVAILABILITY_BROKEN],
    type: 'is-light is-danger',
  },
  [AVAILABILITY_REFORMED]: {
    label: AVAILABILITIES[AVAILABILITY_REFORMED],
    type: 'is-light is-info',
  },
}
