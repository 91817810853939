export default function setupAuth(auth) {
  const state = {
    isAuthenticated: !!auth.getToken(),
    identity: null,
  }

  const actions = {
    // Login action
    async login({ commit }, { email, password }) {
      await auth.login({ email, password })

      commit('SET_IS_AUTHENTICATED', true)
      commit('SET_IDENTITY', null)
    },

    // Get identity
    async loadIdentity({ state, commit, dispatch }) {
      if (state.identity !== null) {
        return
      }

      try {
        commit('SET_IDENTITY', await auth.getIdentity())
      } catch (err) {
        dispatch('logout')
        throw err
      }
    },

    // Logout action
    async logout({ commit }) {
      await auth.logout()

      commit('SET_IS_AUTHENTICATED', false)
      commit('SET_IDENTITY', null)
    },
  }

  const mutations = {
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },

    SET_IDENTITY(state, identity) {
      state.identity = identity
    },
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
  }
}
