<template>
  <div class="columns is-align-items-center mt-3 is-multiline tracker-stats-availabilities">
    <div
      class="column is-narrow is-flex is-flex-direction-column is-align-items-center py-0 tracker-stats-availabilities__details"
    >
      <span
        v-if="loading || !trackerStats.total"
        class="has-text-primary has-text-weight-bold is-size-3"
      >
        —
      </span>
      <router-link
        v-else
        :to="{ name: trackerListRouteName, query: { expired: false } }"
        class="has-text-primary has-text-weight-bold is-size-3"
      >
        {{ trackerStats.total }}
      </router-link>
      <span class="has-text-weight-bold">Abonnées</span>
    </div>
    <div
      v-for="(count, availability) in trackerStats.availabilities"
      :key="availability"
      class="column is-narrow is-flex is-flex-direction-column is-align-items-center p-2"
    >
      <b-tag :type="tags[availability].type" size="is-medium">
        {{ tags[availability].label }}
      </b-tag>
      <span
        v-if="loading || !trackerStats.availabilities"
        class="has-text-primary has-text-weight-bold is-size-5"
      >
        —
      </span>
      <router-link
        v-else
        :to="{ name: trackerListRouteName, query: { availability, expired: false } }"
        class="has-text-primary has-text-weight-bold is-size-5"
      >
        {{ count }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { TAGS } from '@constants/tracker/tag'

export default {
  name: 'TrackerStatsAvailabilities',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    trackerStats: {
      type: Object,
      required: true,
    },
    trackerListRouteName: {
      type: String,
      required: true,
    },
  },

  created() {
    this.tags = TAGS
  },
}
</script>

<style lang="scss">
@import 'variables';

.tracker-stats-availabilities {
  &__details {
    border-right: 2px solid $color-secondary-lightest;
  }
}
</style>
