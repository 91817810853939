<template>
  <svg class="o-icon" xmlns="http://www.w3.org/2000/svg" :class="'o-icon--' + color">
    <use :href="url" />
  </svg>
</template>

<script>
import icons from '@assets/icons.svg'

export default {
  name: 'OIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
  },

  computed: {
    url() {
      return `${icons}#${this.name}`
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

$venus-mars-1: $color-blue;
$venus-mars-2: $color-pink;

.o-icon {
  width: 1em;
  height: 1em;
  font-family: $font-family;
  vertical-align: -0.125em;

  @each $name, $color in $color-markers {
    &--#{$name} {
      color: $color;
    }
  }

  --venus-mars-1: #{$venus-mars-1};
  --venus-mars-2: #{$venus-mars-2};
}
</style>
