export class LiveStagePathService {
  constructor(api) {
    this.api = api
  }

  async replace(id, file) {
    const data = new FormData()
    data.append('file', file)

    return await this.api.post(`/live_stages/${id}/paths`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async update(id, name, liveCategoryId) {
    return await this.api.put(`/live_stage_paths/${id}`, {
      name,
      liveCategoryId,
    })
  }

  async getAll(id) {
    return await this.api.get(`/live_stages/${id}/paths`)
  }

  async exportGpx({ liveStageId }) {
    return await this.api.get(`/live_stages/${liveStageId}/paths/export`)
  }

  async delete(id) {
    return await this.api.delete(`/live_stage_paths/${id}`)
  }
}
