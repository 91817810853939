<template>
  <o-sidebar :isOpen="sidebar.isExpanded" title="Owaka" @close="toggleSidebar">
    <b-menu class="p-4">
      <b-menu-list v-for="category in categories" :key="category.label" :label="category.label">
        <b-menu-item
          v-for="item in category.items"
          :key="item.label"
          :label="item.label"
          tag="router-link"
          :to="{ name: item.routeName }"
          :active="item.routeName === routeName"
          @click.native="closeSideBar"
        />
      </b-menu-list>
    </b-menu>
  </o-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Sidebar from '@components/Sidebar.vue'
import { isMobile } from '@helpers/isMobile'

export default {
  name: 'AppSidebar',

  components: {
    [Sidebar.name]: Sidebar,
  },

  data() {
    return {
      categories: [
        {
          label: 'Général',
          items: [
            {
              label: 'Accueil',
              routeName: 'home',
            },
            {
              label: 'Lives',
              routeName: 'live.list',
            },
            {
              label: 'Tutoriels',
              routeName: 'tutorial.list',
            },
          ],
        },
        {
          label: 'Balises',
          items: [
            {
              label: 'Balises SPOT',
              routeName: 'spotTracker.list',
            },
            {
              label: 'Balises QUECLINK',
              routeName: 'queclinkTracker.list',
            },
          ],
        },
        {
          label: 'Console',
          items: [
            {
              label: 'Administrateurs',
              routeName: 'administrator.list',
            },
          ],
        },
      ],
    }
  },

  beforeMount() {
    this.closeSideBar()
  },

  computed: {
    ...mapState('ui', ['sidebar']),
    ...mapState('route', {
      routeName: (route) => route.name,
    }),
  },

  methods: {
    ...mapActions('ui', ['toggleSidebar']),

    closeSideBar() {
      if (isMobile()) {
        this.toggleSidebar()
      }
    },
  },
}
</script>
