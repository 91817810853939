const AUTH_TOKEN_KEY = 'auth_token'

export class Auth {
  constructor(api, localStorage) {
    this.api = api
    this.localStorage = localStorage

    const token = this.getToken()
    if (token !== null) {
      this.api.setBearerAuthorization(token)
    }
  }

  async login({ email, password }) {
    const { token } = await this.api.post('/auth/login', {
      email,
      password,
    })

    this.api.setBearerAuthorization(token)
    this.localStorage.set(AUTH_TOKEN_KEY, token)

    return true
  }

  logout() {
    this.api.removeBearerAuthorization()
    this.localStorage.remove(AUTH_TOKEN_KEY)
  }

  async getIdentity() {
    return await this.api.get('/auth/me')
  }

  getToken() {
    return this.localStorage.get(AUTH_TOKEN_KEY)
  }
}
