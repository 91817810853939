export class LiveSponsorService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveId) {
    return await this.api.get(`/lives/${liveId}/sponsors`)
  }

  async create({ liveId, image, name, websiteUrl, position }) {
    return await this.api.post('/live_sponsors', {
      liveId,
      image,
      name,
      websiteUrl,
      position,
    })
  }

  async update({ id, image, name, websiteUrl, position }) {
    return await this.api.put(`/live_sponsors/${id}`, {
      image,
      name,
      websiteUrl,
      position,
    })
  }

  async delete(id) {
    return await this.api.delete(`/live_sponsors/${id}`)
  }
}
