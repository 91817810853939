export class LiveExternalLinkService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveId) {
    return await this.api.get(`/lives/${liveId}/external_links`)
  }

  async create({ liveId, url, name, icon, position }) {
    return await this.api.post('/live_external_links', {
      liveId,
      url,
      name,
      icon,
      position,
    })
  }

  async update({ id, url, name, icon, position }) {
    return await this.api.put(`/live_external_links/${id}`, {
      url,
      name,
      icon,
      position,
    })
  }

  async delete(id) {
    return await this.api.delete(`/live_external_links/${id}`)
  }
}
