import { parseISO } from 'date-fns'

export class QueclinkTrackerLocationService {
  constructor(api) {
    this.api = api
  }

  async getAllByQueclinkTrackerId(id, startedAt, endedAt) {
    return (
      await this.api.get(`/queclink_trackers/${id}/locations`, {
        startedAt: startedAt.toISOString(),
        endedAt: endedAt.toISOString(),
      })
    ).map(this.parse)
  }

  parse(queclinkTrackerLocation) {
    queclinkTrackerLocation.locatedAt = parseISO(queclinkTrackerLocation.locatedAt)
    queclinkTrackerLocation.createdAt = parseISO(queclinkTrackerLocation.createdAt)

    return queclinkTrackerLocation
  }
}
