export class LiveVehicleTrackerHistoryService {
  constructor(api) {
    this.api = api
  }

  async switchTrackers({
    administratorId,
    fromLiveVehicleId,
    fromLiveVehicleNumber,
    fromTrackerId,
    fromTrackerType,
    toLiveVehicleId,
    toLiveVehicleNumber,
    toTrackerId,
    toTrackerType,
    comment,
  }) {
    return this.parse(
      await this.api.post('/live_vehicle_tracker_histories', {
        administratorId,
        fromLiveVehicleId,
        fromLiveVehicleNumber,
        fromTrackerId,
        fromTrackerType,
        toLiveVehicleId,
        toLiveVehicleNumber,
        toTrackerId,
        toTrackerType,
        comment,
      }),
    )
  }

  async getAll(liveId) {
    return (await this.api.get(`/lives/${liveId}/vehicle_tracker_histories`)).map(
      (vehicleTrackerHistory) => this.parse(vehicleTrackerHistory),
    )
  }

  parse(vehicleTrackerHistory) {
    vehicleTrackerHistory.updatedAt = new Date(vehicleTrackerHistory.updatedAt)

    return vehicleTrackerHistory
  }
}
