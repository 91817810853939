export class LiveAlertContactService {
  constructor(api) {
    this.api = api
  }

  async create({ liveId, type, email, phoneNumber, name }) {
    return await this.api.post('/live_alert_contacts', {
      liveId,
      type,
      email,
      phoneNumber,
      name,
    })
  }

  async getAll(liveId) {
    return await this.api.get(`/lives/${liveId}/alert_contacts`)
  }

  async update({ id, type, email, phoneNumber, name }) {
    return await this.api.put(`/live_alert_contacts/${id}`, {
      id,
      type,
      email,
      phoneNumber,
      name,
    })
  }

  async delete(id) {
    return await this.api.delete(`/live_alert_contacts/${id}`)
  }
}
