<template>
  <b-sidebar
    :open="isOpen"
    type="is-light"
    fullheight
    mobile="fullwidth"
    :can-cancel="false"
    class="sidebar"
    @close="$emit('close')"
  >
    <div class="level is-mobile p-2 mb-0">
      <p class="level-item level-left title is-5">{{ title }}</p>
      <div class="level-item level-right">
        <b-button
          icon-right="angle-double-left"
          size="is-small"
          type="is-ghost"
          @click="$emit('close')"
        />
      </div>
    </div>
    <slot />
  </b-sidebar>
</template>

<script>
export default {
  name: 'OSidebar',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },
}
</script>
