import Vue from 'vue'
import { AdministratorService } from './administrator'
import { Api } from './api'
import { Auth } from './auth'
import { LiveCategoryService } from './liveCategory'
import { LiveService } from './live'
import { LiveStagePathService } from './liveStagePath'
import { LiveStageRouteService } from './liveStageRoute'
import { LiveStageService } from './liveStage'
import { LiveVehicleService } from './liveVehicle'
import { LocalStorage } from './localStorage'
import { SpotTrackerService } from './spotTracker'
import { LiveStageWaypointService } from './liveStageWaypoint'
import { LiveSettingsService } from './liveSettings'
import { LiveExternalLinkService } from './liveExternalLink'
import { LiveSponsorService } from './liveSponsor'
import { LiveStatService } from './liveStat'
import { LiveChecklistItemService } from './liveChecklistItem'
import { LiveVehicleTrackerHistoryService } from './liveVehicleTrackerHistory'
import { LiveSpotTrackerAvailabilityService } from './liveSpotTrackerAvailability'
import { LiveQueclinkTrackerAvailabilityService } from './liveQueclinkTrackerAvailability'
import { QueclinkTrackerService } from './queclinkTracker'
import { LiveSetupFormService } from './liveSetupForm'
import { LiveDetailService } from './liveDetail'
import { SpotTrackerStatService } from './spotTrackerStat'
import { LiveAlertContactService } from './liveAlertContact'
import { LiveStageSpeedZoneService } from './liveStageSpeedZone'
import { QueclinkTrackerStatService } from './queclinkTrackerStat'
import { LiveDocumentService } from './liveDocument'
import { LiveTutorialService } from './liveTutorial'
import { TranslationService } from './translation'
import { SpotTrackerLocationService } from './spotTrackerLocation'
import { QueclinkTrackerLocationService } from './queclinkTrackerLocation'
import { LiveSpotTrackerConfigService } from './liveSpotTrackerConfig'
import { SpotTrackerProfileService } from './spotTrackerProfile'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.services) {
      this.$services = options.services
    } else if (options.parent && options.parent.$services) {
      this.$services = options.parent.$services
    }
  },
})

export function createServices() {
  const api = new Api(process.env.DASHBOARD_API_URL)
  const localStorage = new LocalStorage()
  const auth = new Auth(api, localStorage)

  const administratorService = new AdministratorService(api)
  const spotTrackerService = new SpotTrackerService(api)
  const queclinkTrackerService = new QueclinkTrackerService(api)
  const liveService = new LiveService(api)
  const liveCategoryService = new LiveCategoryService(api)
  const liveVehicleService = new LiveVehicleService(api)
  const liveStageService = new LiveStageService(api)
  const liveStagePathService = new LiveStagePathService(api)
  const liveSettingsService = new LiveSettingsService(api)
  const liveStageRouteService = new LiveStageRouteService(api)
  const liveStageWaypointService = new LiveStageWaypointService(api)
  const liveStageSpeedZoneService = new LiveStageSpeedZoneService(api)
  const liveExternalLinkService = new LiveExternalLinkService(api)
  const liveSponsorService = new LiveSponsorService(api)
  const liveStatService = new LiveStatService(api)
  const liveSetupFormService = new LiveSetupFormService(api)
  const liveChecklistItemService = new LiveChecklistItemService(api)
  const liveVehicleTrackerHistoryService = new LiveVehicleTrackerHistoryService(api)
  const liveSpotTrackerAvailabilityService = new LiveSpotTrackerAvailabilityService(api)
  const liveSpotTrackerConfigService = new LiveSpotTrackerConfigService(api)
  const liveQueclinkTrackerAvailabilityService = new LiveQueclinkTrackerAvailabilityService(api)
  const liveDetailService = new LiveDetailService(api)
  const spotTrackerStatService = new SpotTrackerStatService(api)
  const spotTrackerLocationService = new SpotTrackerLocationService(api)
  const liveAlertContactService = new LiveAlertContactService(api)
  const queclinkTrackerStatService = new QueclinkTrackerStatService(api)
  const queclinkTrackerLocationService = new QueclinkTrackerLocationService(api)
  const liveDocumentService = new LiveDocumentService(api)
  const liveTutorialService = new LiveTutorialService(api)
  const translationService = new TranslationService(api)
  const spotTrackerProfileService = new SpotTrackerProfileService(api)

  return {
    administratorService,
    spotTrackerService,
    queclinkTrackerService,
    liveService,
    liveCategoryService,
    liveVehicleService,
    liveStageService,
    liveStagePathService,
    liveSettingsService,
    liveStageRouteService,
    liveStageWaypointService,
    liveStageSpeedZoneService,
    liveExternalLinkService,
    liveSponsorService,
    liveStatService,
    liveSetupFormService,
    liveChecklistItemService,
    liveVehicleTrackerHistoryService,
    liveSpotTrackerAvailabilityService,
    liveSpotTrackerConfigService,
    liveQueclinkTrackerAvailabilityService,
    liveDetailService,
    spotTrackerStatService,
    spotTrackerLocationService,
    liveAlertContactService,
    queclinkTrackerStatService,
    queclinkTrackerLocationService,
    liveDocumentService,
    liveTutorialService,
    translationService,
    spotTrackerProfileService,
    auth,
  }
}
