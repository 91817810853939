export class LiveSpotTrackerConfigService {
  constructor(api) {
    this.api = api
  }

  async getById(id) {
    return await this.api.get(`/lives/${id}/spot_tracker_configs`)
  }

  async update({ id, profileId }) {
    return await this.api.put(`/live_spot_tracker_configs/${id}`, {
      profileId,
    })
  }
}
