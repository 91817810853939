import Vue from 'vue'
import Vuex from 'vuex'

import setupAuth from './modules/auth'
import setupUi from './modules/ui'
import plugins from './plugins'

Vue.use(Vuex)

export function createStore(services) {
  const { auth } = services

  return new Vuex.Store({
    modules: {
      ui: setupUi(),
      auth: setupAuth(auth),
    },
    plugins,
    strict: process.env.NODE_ENV !== 'production',
  })
}
