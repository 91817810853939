export class LiveStageService {
  constructor(api) {
    this.api = api
  }

  async create({ liveId, name, accessType, startedAt, endedAt, offTrackLimit }) {
    return this.parse(
      await this.api.post('/live_stages', {
        liveId,
        name,
        accessType,
        startedAt: startedAt.toISOString(),
        endedAt: endedAt.toISOString(),
        offTrackLimit,
      }),
    )
  }

  async getAll(liveId) {
    return (await this.api.get(`/lives/${liveId}/stages`)).map((stage) => this.parse(stage))
  }

  async getById(id) {
    return this.parse(await this.api.get(`/live_stages/${id}`))
  }

  async update(id, { name, accessType, startedAt, endedAt, offTrackLimit }) {
    return this.parse(
      await this.api.put(`/live_stages/${id}`, {
        name,
        accessType,
        startedAt: startedAt.toISOString(),
        endedAt: endedAt.toISOString(),
        offTrackLimit,
      }),
    )
  }

  async delete(id) {
    return await this.api.delete(`/live_stages/${id}`)
  }

  parse(stage) {
    stage.startedAt = new Date(stage.startedAt)
    stage.endedAt = new Date(stage.endedAt)

    return stage
  }
}
