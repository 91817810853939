import { parseISO, format } from 'date-fns'

export class SpotTrackerService {
  constructor(api) {
    this.api = api
  }

  async getAll({ keyword, availability, owner, expired }) {
    return (
      await this.api.get('/spot_trackers', {
        keyword,
        availability,
        owner,
        expired,
      })
    ).map((spotTracker) => this.parse(spotTracker))
  }

  async getAllByLiveId(liveId) {
    return (await this.api.get(`/lives/${liveId}/spot_trackers`)).map(this.parse)
  }

  async getById(id) {
    return this.parse(await this.api.get(`/spot_trackers/${id}`))
  }

  async getLatestLocation(id) {
    return await this.api.get(`/spot_trackers/${id}/latest_location`)
  }

  async update(id, { availability, expiredAt, comment, owner }) {
    return this.parse(
      await this.api.put(`/spot_trackers/${id}`, {
        availability,
        expiredAt: expiredAt ? format(expiredAt, 'yyyy-MM-dd') : expiredAt,
        comment,
        owner,
      }),
    )
  }

  async updateAll({ spotTrackers }) {
    return (
      await this.api.put('/spot_trackers/import', {
        spotTrackers,
      })
    ).map((spotTracker) => this.parse(spotTracker))
  }

  async create({ number, serialNumber, authorizationCode, purchasedAt, comment, owner }) {
    return this.parse(
      await this.api.post('/spot_trackers', {
        number,
        serialNumber,
        authorizationCode,
        purchasedAt: format(purchasedAt, 'yyyy-MM-dd'),
        comment,
        owner,
      }),
    )
  }

  async switchProfile({ id, liveId }) {
    return this.parse(await this.api.put(`/spot_trackers/${id}/switch_profiles`, { liveId }))
  }

  parse(spotTracker) {
    spotTracker.purchasedAt = parseISO(spotTracker.purchasedAt)

    if (spotTracker.expiredAt) {
      spotTracker.expiredAt = parseISO(spotTracker.expiredAt)
    } else {
      spotTracker.expiredAt = null
    }

    return spotTracker
  }
}
