import { parseISO } from 'date-fns'

export class LiveDetailService {
  constructor(api) {
    this.api = api
  }

  async getAll({ statuses }) {
    return (await this.api.get('/lives/details', { statuses })).map((liveDetail) =>
      this.parse(liveDetail),
    )
  }

  parse(liveDetail) {
    liveDetail.live.startedAt = parseISO(liveDetail.live.startedAt)
    liveDetail.live.endedAt = parseISO(liveDetail.live.endedAt)

    return liveDetail
  }
}
