import Vue from 'vue'
import Meta from 'vue-meta'
import Buefy from 'buefy'
import { createRouter } from './router'
import { createStore } from './store'
import { createServices } from './services'
import App from './App.vue'
import OIcon from '@components/Icon.vue'

// Import entry SCSS to generate bundled CSS file
import '@styles/main.scss'

// Import Buefy components
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultUseHtml5Validation: false,
})

// Add vue-meta to manage HTML metadata
Vue.use(Meta, {
  refreshOnceOnNavigaton: true,
})

// Add global components
Vue.component('o-icon', OIcon)

export function createApp() {
  const services = createServices()
  const store = createStore(services)
  const router = createRouter(services, store)

  // Create the Vue app
  const app = new Vue({
    store,
    services,
    router,
    render: (h) => h(App),
  })

  app.$services = services

  return { app, store, router }
}
