const DISMISS_TOAST_AFTER = 3000

export default function setupUi() {
  let currentToastId = 0

  const state = {
    sidebar: {
      isExpanded: true,
    },
    toast: {
      messages: [],
    },
  }

  const actions = {
    toggleSidebar({ state, commit }) {
      commit('SET_SIDEBAR_IS_EXPANDED', !state.sidebar.isExpanded)
    },

    addToastMessage({ commit }, { text, type }) {
      const id = ++currentToastId

      commit('ADD_TOAST_MESSAGE', {
        id,
        text,
        type,
      })

      setTimeout(() => commit('DELETE_TOAST_MESSAGE', id), DISMISS_TOAST_AFTER)
    },
  }

  const mutations = {
    SET_SIDEBAR_IS_EXPANDED(state, isExpanded) {
      state.sidebar.isExpanded = isExpanded
    },

    ADD_TOAST_MESSAGE(state, message) {
      state.toast.messages.push(message)
    },

    DELETE_TOAST_MESSAGE(state, id) {
      state.toast.messages = state.toast.messages.filter((m) => m.id !== id)
    },
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
  }
}
