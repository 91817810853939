<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="column is-6 is-offset-3">
          <div class="card">
            <div class="card-content">
              <h1 class="title has-text-centered">Owaka</h1>
              <p class="subtitle has-text-centered">Dashboard</p>
              <b-notification type="is-danger" :active="error !== null" :closable="false">
                {{ error }}
              </b-notification>
              <form novalidate="true" @submit="submit">
                <b-field
                  v-for="(field, name) in fields"
                  :key="name"
                  :label="field.label"
                  :message="field.error"
                  :type="field.error !== null ? 'is-danger' : ''"
                >
                  <b-input
                    v-model="field.value"
                    :type="field.type"
                    :placeholder="field.placeholder"
                  />
                </b-field>
                <hr />
                <b-field class="has-text-centered">
                  <b-button native-type="submit" type="is-primary" :loading="loading">
                    Se connecter
                  </b-button>
                </b-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginPage',

  data() {
    return {
      fields: {
        email: {
          value: process.env.NODE_ENV === 'development' ? 'foo.bar@owaka.com' : '',
          error: null,
          label: 'Identifiant',
          type: 'email',
          placeholder: 'Login...',
        },
        password: {
          value: process.env.NODE_ENV === 'development' ? 'foobar' : '',
          error: null,
          label: 'Mot de passe',
          type: 'password',
          placeholder: '********',
        },
      },
      error: null,
      loading: false,
    }
  },

  metaInfo() {
    return {
      title: 'Login',
    }
  },

  methods: {
    ...mapActions('auth', ['login']),

    async submit(e) {
      e.preventDefault()
      this.clearFormErrors()

      if (this.fields.email.value === '') {
        this.fields.email.error = "L'email doit être spécifié"
      }

      if (this.fields.password.value === '') {
        this.fields.password.error = 'Le mot de passe doit être spécifié'
      }

      if (this.hasError()) {
        return
      }

      this.loading = true
      try {
        await this.login({
          email: this.fields.email.value,
          password: this.fields.password.value,
        })
        this.$router.push({ name: 'home' })
      } catch (err) {
        this.reportErrors(err)
      }
      this.loading = false
    },

    reportErrors(err) {
      if (err.type && err.type === 'unauthorized') {
        this.error = "Vous n'êtes pas autorisé."
        return
      } else if (err.type && err.type === 'validation-error') {
        this.addFormErrors(err)
        return
      }

      this.error = 'Une erreur interne est survenue.'
    },

    addFormErrors(err) {
      for (let name in err.details) {
        this.fields[name].error = err.details[name].type
      }
    },

    clearFormErrors() {
      for (let name in this.fields) {
        this.fields[name].error = null
      }
      this.error = null
    },

    clearFormValues() {
      for (let name in this.fields) {
        this.fields[name].value = ''
      }
    },

    hasError() {
      for (let name in this.fields) {
        if (this.fields[name].error) {
          return true
        }
      }

      return this.error !== null
    },
  },
}
</script>
