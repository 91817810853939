import { parseISO } from 'date-fns'

export class SpotTrackerLocationService {
  constructor(api) {
    this.api = api
  }

  async getAllBySpotTrackerId(id, startedAt, endedAt) {
    return (
      await this.api.get(`/spot_trackers/${id}/locations`, {
        startedAt: startedAt.toISOString(),
        endedAt: endedAt.toISOString(),
      })
    ).map(this.parse)
  }

  parse(spotTrackerLocation) {
    spotTrackerLocation.locatedAt = parseISO(spotTrackerLocation.locatedAt)
    spotTrackerLocation.createdAt = parseISO(spotTrackerLocation.createdAt)

    return spotTrackerLocation
  }
}
