<template>
  <b-navbar class="o-navbar" shadow :mobile-burger="navItems.length > 0">
    <template #brand>
      <h1 class="title is-align-self-center my-0 mr-4">
        {{ title }}
      </h1>
    </template>
    <template #end>
      <b-navbar-item
        v-for="navItem in navItems"
        :key="navItem.label"
        :to="navItem.to"
        :active="navItem.active"
        tag="router-link"
      >
        {{ navItem.label }}
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'ONavbar',

  props: {
    title: {
      type: String,
      required: true,
    },

    navItems: {
      type: Array,
      default: () => [],
      validator: (v) => v.every((obj) => ['to', 'label', 'active'].every((key) => key in obj)),
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

.o-navbar {
  .navbar-brand {
    flex: 2;
  }

  .navbar-menu {
    flex: 1;
  }

  a.navbar-item.is-active {
    color: $primary;
  }
}
</style>
