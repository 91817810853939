export class LiveSpotTrackerAvailabilityService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveId) {
    return (await this.api.get(`/lives/${liveId}/spot_tracker_availabilities`)).map(
      (liveSpotTrackerAvailability) => this.parse(liveSpotTrackerAvailability),
    )
  }

  async update({ id, availability }) {
    return this.parse(
      await this.api.put(`/live_spot_tracker_availabilities/${id}`, {
        availability,
      }),
    )
  }

  async updateAll({ liveId, liveSpotTrackerAvailabilities }) {
    return (
      await this.api.put(`/lives/${liveId}/spot_tracker_availabilities`, {
        liveSpotTrackerAvailabilities,
      })
    ).map((liveSpotTrackerAvailability) => this.parse(liveSpotTrackerAvailability))
  }

  parse(liveSpotTrackerAvailability) {
    liveSpotTrackerAvailability.updatedAt = new Date(liveSpotTrackerAvailability.updatedAt)

    return liveSpotTrackerAvailability
  }
}
