<template>
  <div id="app">
    <client-only>
      <router-view />
    </client-only>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'

export default {
  name: 'App',

  components: {
    [ClientOnly.name]: ClientOnly,
  },

  metaInfo: {
    titleTemplate: '%s // Owaka dashboard',
  },
}
</script>
