import InMemoryStorage from './InMemoryStorage'

export class LocalStorage {
  constructor() {
    this.storage = typeof window !== 'undefined' ? window.localStorage : new InMemoryStorage()
  }

  set(key, value) {
    this.storage.setItem(key, value)
  }

  get(key) {
    return this.storage.getItem(key)
  }

  remove(key) {
    this.storage.removeItem(key)
  }
}
