import { SpeedZone } from '@views/pages/EditLivePage/EditLiveAddSpeedZoneTab'

export class LiveStageSpeedZoneService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveStageId) {
    return (await this.api.get(`/live_stages/${liveStageId}/speed_zones`)).map(
      (speedZone) => new SpeedZone(speedZone),
    )
  }

  async create({
    liveStageId,
    liveStageRouteId,
    liveCategoryId,
    name,
    color,
    speed,
    distance,
    path,
    position,
  }) {
    return new SpeedZone(
      await this.api.post(`/live_stages/${liveStageId}/speed_zones`, {
        liveStageRouteId,
        liveCategoryId,
        name,
        color,
        speed,
        distance,
        path,
        position,
      }),
    )
  }

  async update(id, { liveCategoryId, name, color, speed, distance, path, position }) {
    return new SpeedZone(
      await this.api.put(`/live_stage_speed_zones/${id}`, {
        liveCategoryId,
        name,
        color,
        speed,
        distance,
        path,
        position,
      }),
    )
  }

  async delete(id) {
    return new SpeedZone(await this.api.delete(`/live_stage_speed_zones/${id}`))
  }
}
