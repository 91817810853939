export const AVAILABILITY_LOST = 'lost'
export const AVAILABILITY_BROKEN = 'broken'
export const AVAILABILITY_REFORMED = 'reformed'
export const AVAILABILITY_AVAILABLE = 'available'
export const AVAILABILITY_UNAVAILABLE = 'unavailable'

export const AVAILABILITIES = {
  [AVAILABILITY_LOST]: 'Perdu',
  [AVAILABILITY_BROKEN]: 'Cassé',
  [AVAILABILITY_REFORMED]: 'Réformé',
  [AVAILABILITY_AVAILABLE]: 'Disponible',
  [AVAILABILITY_UNAVAILABLE]: 'Non disponible',
}
