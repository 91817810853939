<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Page non trouvée</h1>
        <h2 class="subtitle">La page que vous tentez d'afficher n'existe pas.</h2>
        <b-button tag="router-link" :to="{ name: 'home' }" type="is-primary">
          <b-icon icon="arrow-left" />
          <span>Retour à la page d'accueil</span>
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Error404Page',
}
</script>
