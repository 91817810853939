export class LiveStatService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveId) {
    return await this.api.get(`/lives/${liveId}/stats`)
  }

  async create({ liveId, label, value, icon, position }) {
    return await this.api.post('/live_stats', {
      liveId,
      label,
      value,
      icon,
      position,
    })
  }

  async update({ id, label, value, icon, position }) {
    return await this.api.put(`/live_stats/${id}`, {
      label,
      value,
      icon,
      position,
    })
  }

  async delete(id) {
    return await this.api.delete(`/live_stats/${id}`)
  }
}
