export class TranslationService {
  constructor(api) {
    this.api = api
  }

  async translate({ value, fromLocale, toLocale }) {
    return await this.api.get('/translations', {
      value,
      fromLocale,
      toLocale,
    })
  }
}
