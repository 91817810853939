import lineSlice from '@turf/line-slice'
import { point, lineString } from '@turf/helpers'
import nearestPointOnLine from '@turf/nearest-point-on-line'

export class SpeedZone {
  constructor({
    id,
    path,
    name,
    color = 'red',
    speed = 50,
    distance = 500,
    position = 1,
    liveStageRouteId = null,
    liveStageRoutePath = null,
    hasErrorOnCreate = false,
    liveCategoryId = null,
  }) {
    this.id = id
    this.path = path
    this.name = name
    this.color = color
    this.speed = speed
    this.distance = distance
    this.position = position
    this.liveStageRouteId = liveStageRouteId
    this.liveStageRoutePath = liveStageRoutePath
    this.hasErrorOnCreate = hasErrorOnCreate
    this.liveCategoryId = liveCategoryId

    this._start = path.at(0)
    this._end = path.at(-1)
  }

  set start(latLng) {
    if (this.liveStageRouteId && this.liveStageRoutePath) {
      this._start = this.getNearestPointOnLiveStageRoute(latLng)
      this.path = this.getSlicedPathFromLiveStageRoute()

      return
    }

    this._start = latLng
    this.path = [this._start, this._end]
  }

  get start() {
    return this._start
  }

  set end(latLng) {
    if (this.liveStageRouteId && this.liveStageRoutePath) {
      this._end = this.getNearestPointOnLiveStageRoute(latLng)
      this.path = this.getSlicedPathFromLiveStageRoute()

      return
    }

    this._end = latLng
    this.path = [this._start, this._end]
  }

  get end() {
    return this._end
  }

  getSlicedPathFromLiveStageRoute() {
    const slicedLine = lineSlice(
      this.getTurfPoint(this._start),
      this.getTurfPoint(this._end),
      this.getTurfLineString(this.liveStageRoutePath),
    )

    return slicedLine.geometry.coordinates.map(([lng, lat]) => [lat, lng])
  }

  getNearestPointOnLiveStageRoute(latLng) {
    const [lng, lat] = nearestPointOnLine(
      this.getTurfLineString(this.liveStageRoutePath),
      this.getTurfPoint(latLng),
    ).geometry.coordinates

    return [lat, lng]
  }

  getTurfPoint([lat, lng]) {
    return point([lng, lat])
  }

  getTurfLineString(path) {
    return lineString(path.map(([lat, lng]) => [lng, lat]))
  }
}
