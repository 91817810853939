export class LiveChecklistItemService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveId) {
    return (await this.api.get(`/lives/${liveId}/checklist_items`)).map((liveChecklistItem) =>
      this.parse(liveChecklistItem),
    )
  }

  async update({ id, done, administratorId }) {
    return this.parse(
      await this.api.put(`/live_checklist_items/${id}`, {
        done,
        administratorId,
      }),
    )
  }

  parse(liveChecklistItem) {
    liveChecklistItem.updatedAt = new Date(liveChecklistItem.updatedAt)

    return liveChecklistItem
  }
}
