export class LiveQueclinkTrackerAvailabilityService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveId) {
    return (await this.api.get(`/lives/${liveId}/queclink_tracker_availabilities`)).map(
      (liveQueclinkTrackerAvailability) => this.parse(liveQueclinkTrackerAvailability),
    )
  }

  async updateAll({ liveId, liveQueclinkTrackerAvailabilities }) {
    return (
      await this.api.put(`/lives/${liveId}/queclink_tracker_availabilities`, {
        liveQueclinkTrackerAvailabilities,
      })
    ).map((liveQueclinkTrackerAvailability) => this.parse(liveQueclinkTrackerAvailability))
  }

  parse(liveQueclinkTrackerAvailability) {
    liveQueclinkTrackerAvailability.updatedAt = new Date(liveQueclinkTrackerAvailability.updatedAt)

    return liveQueclinkTrackerAvailability
  }
}
