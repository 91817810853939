export class LiveDocumentService {
  constructor(api) {
    this.api = api
  }

  async getVehicles({ liveId, name, email, phone }) {
    return await this.api.get(`/lives/${liveId}/documents/vehicles`, { name, email, phone }, 'blob')
  }

  async getDeposits({ liveId, name, email, phone }) {
    return await this.api.get(`/lives/${liveId}/documents/deposits`, { name, email, phone }, 'blob')
  }

  async getAlertContacts({ liveId, name, email, phone }) {
    return await this.api.get(
      `/lives/${liveId}/documents/alert_contacts`,
      { name, email, phone },
      'blob',
    )
  }
}
