<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="columns is-multiline">
      <tracker-stats
        v-for="trackerStat of trackerStats"
        :key="trackerStat.type"
        class="column is-full"
        :loading="loading"
        :title="trackerStat.title"
        :tracker-stats="trackerStat.stats"
        :live-details="trackerStat.liveDetails"
        :tracker-list-route-name="trackerStat.route"
      />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import TrackerStats from '@views/TrackerStat'
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type'

export default {
  name: 'HomePage',

  components: {
    TrackerStats,
    [PageLayout.name]: PageLayout,
  },

  data() {
    return {
      title: 'Dashboard',
      loading: false,
      trackerStats: [],
    }
  },

  async created() {
    this.loading = true

    const [liveDetails, spotTrackerStats, queclinkTrackerStats] = await Promise.all([
      this.fetchLiveDetails(),
      this.fetchSpotTrackerStats(),
      this.fetchQueclinkTrackerStats(),
    ])

    this.loading = false

    const trackerLiveDetails = this.formatLiveDetails(liveDetails)

    this.trackerStats = [
      {
        title: 'Balises SPOT',
        route: 'spotTracker.list',
        stats: spotTrackerStats,
        liveDetails: trackerLiveDetails[SPOT_TRACKER_TYPE],
      },
      {
        title: 'Balises QUECLINK',
        route: 'queclinkTracker.list',
        stats: queclinkTrackerStats,
        liveDetails: trackerLiveDetails[QUECLINK_TRACKER_TYPE],
      },
    ]
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  methods: {
    formatLiveDetails(liveDetails) {
      return liveDetails.reduce(
        (
          trackerLiveDetails,
          {
            live,
            spotTrackerCount,
            queclinkTrackerCount,
            unavailableLiveSpotTrackerCount,
            unavailableLiveQueclinkTrackerCount,
          },
        ) => ({
          [SPOT_TRACKER_TYPE]: [
            ...trackerLiveDetails[SPOT_TRACKER_TYPE],
            {
              live,
              trackerCount: spotTrackerCount,
              unavailableTrackerCount: unavailableLiveSpotTrackerCount,
            },
          ],
          [QUECLINK_TRACKER_TYPE]: [
            ...trackerLiveDetails[QUECLINK_TRACKER_TYPE],
            {
              live,
              trackerCount: queclinkTrackerCount,
              unavailableTrackerCount: unavailableLiveQueclinkTrackerCount,
            },
          ],
        }),
        { [SPOT_TRACKER_TYPE]: [], [QUECLINK_TRACKER_TYPE]: [] },
      )
    },

    async fetchLiveDetails() {
      try {
        return await this.$services.liveDetailService.getAll({
          statuses: ['pending', 'completed'],
        })
      } catch (err) {
        return []
      }
    },

    async fetchSpotTrackerStats() {
      try {
        return await this.$services.spotTrackerStatService.getAll({
          expired: false,
        })
      } catch (err) {
        return []
      }
    },

    async fetchQueclinkTrackerStats() {
      try {
        return await this.$services.queclinkTrackerStatService.getAll()
      } catch (err) {
        return []
      }
    },
  },
}
</script>
