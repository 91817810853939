export class LiveStageWaypointService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveStageId) {
    return await this.api.get(`/live_stages/${liveStageId}/waypoints`)
  }

  async replace(liveStageId, file) {
    const data = new FormData()
    data.append('file', file)
    return await this.api.post(`/live_stages/${liveStageId}/waypoints`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async create({
    liveStageId,
    name,
    icon,
    color,
    accessType,
    location,
    radius,
    role,
    label,
    liveCategoryId,
  }) {
    return await this.api.post('live_stage_waypoints', {
      liveStageId,
      name,
      icon,
      color,
      accessType,
      location,
      radius,
      role,
      label,
      liveCategoryId,
    })
  }

  async update(
    id,
    { name, icon, color, accessType, position, radius, role, label, liveCategoryId },
  ) {
    return await this.api.put(`/live_stage_waypoints/${id}`, {
      name,
      icon,
      color,
      accessType,
      position,
      radius,
      role,
      label,
      liveCategoryId,
    })
  }

  async updateAll({ liveStageId, waypoints }) {
    return await this.api.put(`/live_stages/${liveStageId}/waypoints`, { waypoints })
  }

  async delete(id) {
    return await this.api.delete(`/live_stage_waypoints/${id}`)
  }

  async deleteAll({ waypointIds }) {
    return await this.api.delete('live_stage_waypoints', { waypointIds })
  }

  async exportGpx({ liveStageId }) {
    return await this.api.get(`live_stages/${liveStageId}/waypoints/export`)
  }
}
